import { axios } from '@/utils/request'

export function loadTestRecords (parameter) {
  return axios({
    url: '/user/test/data/list',
    method: 'post',
    data: parameter
  })
}

export function loadUser007TestDataList (parameter) {
  return axios({
    url: '/user/007/test/data/list',
    method: 'post',
    data: parameter
  })
}

export function loadUserBreathmeterTestDataList (parameter) {
  return axios({
    url: '/user/breathmeter/test/data/list',
    method: 'post',
    data: parameter
  })
}

export function load007TestDetailData (parameter) {
  return axios({
    url: '/user/007/test/data/detail',
    method: 'post',
    data: parameter
  })
}

export function exportTodayTestRecords (parameter) {
  return axios({
    url: '/user/test/data/today/export',
    method: 'post',
    data: parameter,
    timeout: 90000,
    responseType: 'blob'
  })
}

export function loadErrorRecords (parameter) {
  return axios({
    url: '/user/test/err/list',
    method: 'post',
    data: parameter
  })
}

export function loadAppList (parameter) {
  return axios({
    url: '/app/info/list',
    method: 'post',
    data: parameter
  })
}

export function createApp (parameter) {
  return axios({
    url: '/app/info/create',
    method: 'post',
    data: parameter
  })
}

export function loadAllAPPList (parameter) {
  return axios({
    url: '/app/all/list',
    method: 'post',
    data: parameter
  })
}

export function loadAppUpgradeInfoList (parameter) {
  return axios({
    url: '/app/upgrade/info/list',
    method: 'post',
    data: parameter
  })
}

export function loadTestDetailData (parameter) {
  return axios({
    url: '/user/test/data/detail',
    method: 'post',
    data: parameter
  })
}

export function loadDeviceCalInfoDetail (parameter) {
  return axios({
    url: '/user/dev/cal/detail',
    method: 'post',
    data: parameter
  })
}

export function loadErrorDetailData (parameter) {
  return axios({
    url: '/user/test/err/detail',
    method: 'post',
    data: parameter
  })
}

export function loadFileShareList (parameter) {
  return axios({
    url: '/share/file/list',
    method: 'post',
    data: parameter
  })
}

export function deleteCalData (parameter) {
  return axios({
    url: '/data/cal/del',
    method: 'post',
    data: parameter
  })
}

export function resetInfo (parameter) {
  return axios({
    url: '/auth/password/reset',
    method: 'post',
    data: parameter
  })
}

export function load012UUserTestDataList (parameter) {
  return axios({
    url: '/user/test/012u/data/list',
    method: 'post',
    data: parameter
  })
}

export function loadAcetoneUserTestDataList (parameter) {
  return axios({
    url: '/user/test/acetone/data/list',
    method: 'post',
    data: parameter
  })
}

export function load012UUnitList (parameter) {
  return axios({
    url: '/012u/unit/list',
    method: 'post',
    data: parameter
  })
}

export function load012UTestDetailData (parameter) {
  return axios({
    url: '/012u/test/data/detail',
    method: 'post',
    data: parameter
  })
}

export function loadAcetoneTestDetailData (parameter) {
  return axios({
    url: '/acetone/test/data/detail',
    method: 'post',
    data: parameter
  })
}

export function delete012UCalData (parameter) {
  return axios({
    url: '/012u/cal/data/del',
    method: 'post',
    data: parameter
  })
}

export function deleteSensorCalData (parameter) {
  return axios({
    url: '/sensor/cal/data/del',
    method: 'post',
    data: parameter
  })
}

export function loadCompanyList (parameter) {
  return axios({
    url: '/company/list',
    method: 'post',
    data: parameter
  })
}

export function saveCompanyInfo (parameter) {
  return axios({
    url: '/company/info/save',
    method: 'post',
    data: parameter
  })
}

export function delCompanyInfo (parameter) {
  return axios({
    url: '/company/info/del',
    method: 'post',
    data: parameter
  })
}

export function saveApplicationInfo (parameter) {
  return axios({
    url: '/app/sys/application/save',
    method: 'post',
    data: parameter
  })
}

export function delApplicationInfo (parameter) {
  return axios({
    url: '/app/sys/application/del',
    method: 'post',
    data: parameter
  })
}

export function load007TodayTestLocationList (parameter) {
  return axios({
    url: '/007/test/location/data',
    method: 'post',
    data: parameter
  })
}

export function loadTodayTestLocationList (parameter) {
  return axios({
    url: '/keiyo/test/location/data',
    method: 'post',
    data: parameter
  })
}

export function loadKeiyoDeviceStatData (parameter) {
  return axios({
    url: '/keiyo/test/stat/data',
    method: 'post',
    data: parameter
  })
}

export function loadK007DeviceStatData (parameter) {
  return axios({
    url: '/007/test/stat/data',
    method: 'post',
    data: parameter
  })
}

export function exportKeiyoDeviceStatData (parameter) {
  return axios({
    url: '/keiyo/test/stat/data/export',
    responseType: 'blob',
    method: 'post',
    data: parameter
  })
}

export function export007DeviceStatData (parameter) {
  return axios({
    url: '/007/test/stat/data/export',
    responseType: 'blob',
    method: 'post',
    data: parameter
  })
}

export function loadApplicationList (parameter) {
  return axios({
    url: '/app/sys/application/list',
    method: 'post',
    data: parameter
  })
}

export function delAdImage (parameter) {
  return axios({
    url: '/ad/image/del',
    method: 'post',
    data: parameter
  })
}

export function loadPhCalDataList (parameter) {
  return axios({
    url: '/ph/cal/list',
    method: 'post',
    data: parameter
  })
}

export function findCompanyOfDevice (parameter) {
  return axios({
    url: '/ad/device/company/find',
    method: 'post',
    data: parameter
  })
}

export function loadAllCompanyList (parameter) {
  return axios({
    url: '/ad/company/all/list',
    method: 'post',
    data: parameter
  })
}

export function bindDeviceToCompany (parameter) {
  return axios({
    url: '/ad/device/company/bind',
    method: 'post',
    data: parameter
  })
}

export function load012UCalibrationDataList (parameter) {
  return axios({
    url: '/012u/cal/data/list',
    method: 'post',
    data: parameter
  })
}

export function load012B32CalibrationDataList (parameter) {
  return axios({
    url: '/012b32/cal/data/list',
    method: 'post',
    data: parameter
  })
}

export function loadSensorCalibrationDataList (parameter) {
  return axios({
    url: '/sensor/cal/data/list',
    method: 'post',
    data: parameter
  })
}

export function loadLabCalibrationDataList (parameter) {
  return axios({
    url: '/lab/cal/data/list',
    method: 'post',
    data: parameter
  })
}

export function loadKeiyoCalibrationDataList (parameter) {
  return axios({
    url: '/keiyo/cal/data/list',
    method: 'post',
    data: parameter
  })
}

export function loadKeiyoCalibrationErrList (parameter) {
  return axios({
    url: '/keiyo/cal/err/list',
    method: 'post',
    data: parameter
  })
}

export function saveAdImageLinkInfo (parameter) {
  return axios({
    url: '/ad/link/info/save',
    method: 'post',
    data: parameter
  })
}

export function loadAppLogList (parameter) {
  return axios({
    url: '/app/log/list',
    method: 'post',
    data: parameter
  })
}

export function loadSubUnitList (parameter) {
  return axios({
    url: '/app/unit/sub/list',
    method: 'post',
    data: parameter
  })
}

export function saveSubUnitInfo (parameter) {
  return axios({
    url: '/app/unit/sub/info/save',
    method: 'post',
    data: parameter
  })
}

export function deleteSubUnitInfo (parameter) {
  return axios({
    url: '/app/unit/sub/info/del',
    method: 'post',
    data: parameter
  })
}

export function loadAdminList (parameter) {
  return axios({
    url: '/app/unit/sub/admin/list',
    method: 'post',
    data: parameter
  })
}

export function saveSubUnitAdminInfo (parameter) {
  return axios({
    url: '/app/unit/sub/admin/save',
    method: 'post',
    data: parameter
  })
}

export function deleteSubUnitAdmin (parameter) {
  return axios({
    url: '/app/unit/sub/admin/del',
    method: 'post',
    data: parameter
  })
}

export function updateAdminPassword (parameter) {
  return axios({
    url: '/app/unit/sub/admin/password/update',
    method: 'post',
    data: parameter
  })
}

export function loadDeviceModelList (parameter) {
  return axios({
    url: '/device/model/list',
    method: 'post',
    data: parameter
  })
}

export function addDeviceModel (parameter) {
  return axios({
    url: '/device/model/add',
    method: 'post',
    data: parameter
  })
}

export function loadDeviceTypeList (parameter) {
  return axios({
    url: '/device/model/list',
    method: 'post',
    data: parameter
  })
}

export function deleteDeviceType (parameter) {
  return axios({
    url: '/device/model/del',
    method: 'post',
    data: parameter
  })
}

export function delAppInfo (parameter) {
  return axios({
    url: '/app/info/del',
    method: 'post',
    data: parameter
  })
}

export function loadFactoryAppUserList (parameter) {
  return axios({
    url: '/app/factory/user/list',
    method: 'post',
    data: parameter
  })
}

export function toggleFactoryUserStatus (parameter) {
  return axios({
    url: '/app/factory/user/status/update',
    method: 'post',
    data: parameter
  })
}

export function loadAppPropList (parameter) {
  return axios({
    url: '/app/unit/prop/list',
    method: 'post',
    data: parameter
  })
}

export function saveUnitAppPropInfo (parameter) {
  return axios({
    url: '/app/unit/prop/save',
    method: 'post',
    data: parameter
  })
}

export function delUnitAppPropInfo (parameter) {
  return axios({
    url: '/app/unit/prop/del',
    method: 'post',
    data: parameter
  })
}

export function loadKeiyoUserList (parameter) {
  return axios({
    url: '/keiyo/user/list',
    method: 'post',
    data: parameter
  })
}

export function loadAppPropsList (parameter) {
  return axios({
    url: '/app/prop/list',
    method: 'post',
    data: parameter
  })
}

export function loadKeiyoUserAppProps (parameter) {
  return axios({
    url: '/app/keiyo/user/prop',
    method: 'post',
    data: parameter
  })
}

export function saveUserProp (parameter) {
  return axios({
    url: '/app/keiyo/user/prop/save',
    method: 'post',
    data: parameter
  })
}

export function delUserProp (parameter) {
  return axios({
    url: '/app/keiyo/user/prop/del',
    method: 'post',
    data: parameter
  })
}

export function toggleKeiyoUserStatus (parameter) {
  return axios({
    url: '/app/keiyo/user/status/update',
    method: 'post',
    data: parameter
  })
}

export function loadTeaScaleCalibrationList (parameter) {
  return axios({
    url: '/app/scale/tea/calibration/list',
    method: 'post',
    data: parameter
  })
}

export function loadWebSiteMonitorList (parameter) {
  return axios({
    url: '/web/site/monitor/list',
    method: 'post',
    data: parameter
  })
}

export function saveWebSiteMonitorInfo (parameter) {
  return axios({
    url: '/web/site/monitor/save',
    method: 'post',
    data: parameter
  })
}

export function deleteWebSiteMonitorInfo (parameter) {
  return axios({
    url: '/web/site/monitor/del',
    method: 'post',
    data: parameter
  })
}

export function loadAlertContactList (parameter) {
  return axios({
    url: '/web/alert/contact/list',
    method: 'post',
    data: parameter
  })
}

export function saveAlertContactInfo (parameter) {
  return axios({
    url: '/web/alert/contact/save',
    method: 'post',
    data: parameter
  })
}

export function deleteAlertContactInfo (parameter) {
  return axios({
    url: '/web/alert/contact/del',
    method: 'post',
    data: parameter
  })
}

export function loadKeiyoAPPComplatiableInfo (parameter) {
  return axios({
    url: '/keiyo/app/complatiable/list',
    method: 'post',
    data: parameter
  })
}

export function exportDataToExcel (parameter) {
  return axios({
    url: '/common/data/export',
    method: 'post',
    responseType: 'blob',
    data: parameter
  })
}

export function saveAppUpgradeInfo (parameter) {
  return axios({
    url: '/app/upgrade/info/save',
    method: 'post',
    data: parameter
  })
}

export function delAppUpgradeInfo (parameter) {
  return axios({
    url: '/app/upgrade/info/del',
    method: 'post',
    data: parameter
  })
}

export function loadDeviceCalibrationErrorList (parameter) {
  return axios({
    url: '/device/calibration/err/list',
    method: 'post',
    data: parameter
  })
}

export function processDeviceCalibrationError (parameter) {
  return axios({
    url: '/device/cal/missing/process',
    method: 'post',
    data: parameter
  })
}

export function loadCompanyDeviceList (parameter) {
  return axios({
    url: '/company/device/list',
    method: 'post',
    data: parameter
  })
}

export function AddCompanyDevice (parameter) {
  return axios({
    url: '/company/device/add',
    method: 'post',
    data: parameter
  })
}

export function delCompanyDevice (parameter) {
  return axios({
    url: '/company/device/del',
    method: 'post',
    data: parameter
  })
}

export function loadkeiyoTestDataOfDur (parameter) {
  return axios({
    url: '/keiyo/dur/test/data',
    method: 'post',
    data: parameter
  })
}

export function loadKeiyoActiveDeviceOfDay (parameter) {
  return axios({
    url: '/keiyo/active/device/list',
    method: 'post',
    data: parameter
  })
}

export function exportKeiyoActiveDeviceOfDay (parameter) {
  return axios({
    url: '/keiyo/active/device/export',
    method: 'post',
    responseType: 'blob',
    data: parameter
  })
}

export function loadSensorTestList (parameter) {
  return axios({
    url: '/sensor/test/data/list',
    method: 'post',
    data: parameter
  })
}

export function loadSensorTestDetail (parameter) {
  return axios({
    url: '/sensor/test/data/detail',
    method: 'post',
    timeout: 30000,
    data: parameter
  })
}

export function loadSensorAlarmList (parameter) {
  return axios({
    url: '/sensor/alarm/data/list',
    method: 'post',
    data: parameter
  })
}

export function disconnect4GSensorDevice (parameter) {
  return axios({
    url: '/4g/device/disconnect',
    method: 'post',
    data: parameter
  })
}


export function loadDeviceInfo4G (parameter) {
  return axios({
    url: '/4g/device/info/load',
    method: 'post',
    timeout: 30000,
    data: parameter
  })
}

export function loadDeviceFirmwareInfoList (parameter) {
  return axios({
    url: '/device/firmware/info/list',
    method: 'post',
    data: parameter
  })
}

export function saveDeviceFirmwareInfo (parameter) {
  return axios({
    url: '/device/firmware/info/save',
    method: 'post',
    data: parameter
  })
}

export function delDeviceFirmwareInfo (parameter) {
  return axios({
    url: '/device/firmware/info/del',
    method: 'post',
    data: parameter
  })
}

export function switchDeviceFirmwareInfoStatus (parameter) {
  return axios({
    url: '/device/firmware/status/switch',
    method: 'post',
    data: parameter
  })
}

export function switchDeviceFirmwareInfoForceUpdate (parameter) {
  return axios({
    url: '/device/firmware/force/update/switch',
    method: 'post',
    data: parameter
  })
}

export function loadFoodScaleUserTodayStat (parameter) {
  return axios({
    url: '/food/scale/stat/today',
    method: 'post',
    timeout: 80000,
    data: parameter
  })
}

export function teaScaleLocationLoad (parameter) {
  return axios({
    url: '/food/scale/location/query',
    method: 'post',
    timeout: 80000,
    data: parameter
  })
}

export function load012DeviceStatInfoList (parameter) {
  return axios({
    url: '/device/012/stat/info/list',
    method: 'post',
    data: parameter
  })
}

export function load012DeviceCompatibleInfoList (parameter) {
  return axios({
    url: '/device/012/compatible/info/list',
    method: 'post',
    data: parameter
  })
}

export function export012DeviceCompatibleInfoList (parameter) {
  return axios({
    url: '/device/012/compatible/info/export',
    method: 'post',
    responseType: 'blob',
    data: parameter
  })
}

export function load012DeviceAraStat (parameter) {
  return axios({
    url: '/device/012/area/stat',
    method: 'post',
    data: parameter
  })
}

export function load012DeviceDailyTestCountStat (parameter) {
  return axios({
    url: '/device/012/test/count/stat',
    method: 'post',
    data: parameter
  })
}

export function load012DeviceLocationList (parameter) {
  return axios({
    url: '/device/012/test/location/list',
    method: 'post',
    timeout: 10000,
    data: parameter
  })
}

export function loadKetoneStatInfoList (parameter) {
  return axios({
    url: '/device/ketone/stat/info/list',
    method: 'post',
    data: parameter
  })
}

export function loadKetoneAraStat (parameter) {
  return axios({
    url: '/device/ketone/area/stat',
    method: 'post',
    data: parameter
  })
}

export function loadKetoneDailyTestCountStat (parameter) {
  return axios({
    url: '/device/ketone/test/count/stat',
    method: 'post',
    data: parameter
  })
}

export function loadKetoneLocationList (parameter) {
  return axios({
    url: '/device/ketone/test/location/list',
    method: 'post',
    data: parameter
  })
}

export function loadTeaScaleUserList (parameter) {
  return axios({
    url: '/food/scale/user/list',
    method: 'post',
    data: parameter
  })
}

export function loadTracerTestList (parameter) {
  return axios({
    url: '/tracer/test/data/list',
    method: 'post',
    timeout: 30000,
    data: parameter
  })
}

export function loadTracerTestDetailData (parameter) {
  return axios({
    url: '/tracer/test/data/detail',
    method: 'post',
    timeout: 30000,
    data: parameter
  })
}

export function loadSleepSensorCalibration (parameter) {
  return axios({
    url: '/sensor/sleep/calibration/load',
    method: 'post',
    timeout: 30000,
    data: parameter
  })
}

export function loadKeiyoDevDurationTestStat (parameter) {
  return axios({
    url: '/keiyo/dev/dur/test/stat/load',
    method: 'post',
    timeout: 30000,
    data: parameter
  })
}

export function exportKeiyoDevDurationTestStat (parameter) {
  return axios({
    url: '/keiyo/dev/dur/test/stat/export',
    method: 'post',
    timeout: 30000,
    responseType: 'blob',
    data: parameter
  })
}

export function loadScaleAdInfoList (parameter) {
  return axios({
    url: '/scale/ad/info/list',
    method: 'post',
    data: parameter
  })
}

export function saveScaleAdInfo (parameter) {
  return axios({
    url: '/scale/ad/info/save',
    method: 'post',
    data: parameter
  })
}

export function delScaleAdInfo (parameter) {
  return axios({
    url: '/scale/ad/info/del',
    method: 'post',
    data: parameter
  })
}

export function loadAllScaleClientCountry (parameter) {
  return axios({
    url: '/food/scale/country/list',
    method: 'post',
    data: parameter
  })
}

export function loadCheckerDriverList (parameter) {
  return axios({
    url: '/checker/driver/list',
    method: 'post',
    data: parameter
  })
}

export function saveLab012DeviceInfo (parameter) {
  return axios({
    url: '/lab/012/device/info/save',
    method: 'post',
    data: parameter
  })
}

export function deleteLab012DeviceInfo (parameter) {
  return axios({
    url: '/lab/012/device/info/del',
    method: 'post',
    data: parameter
  })
}

export function loadLab012DeviceList (parameter) {
  return axios({
    url: '/lab/012/device/info/list',
    method: 'post',
    data: parameter
  })
}

export function loadAppCommentList (parameter) {
  return axios({
    url: '/app/comment/list',
    method: 'post',
    data: parameter
  })
}

export function updateAppCommentReplyStatus (parameter) {
  return axios({
    url: '/app/comment/reply',
    method: 'post',
    data: parameter
  })
}

export function loadShopProductInfoList (parameter) {
  return axios({
    url: '/shop/product/info/list',
    method: 'post',
    data: parameter
  })
}

export function loadShopCategoryInfoList (parameter) {
  return axios({
    url: '/shop/product/category/list',
    method: 'post',
    data: parameter
  })
}

export function saveShopProductInfo (parameter) {
  return axios({
    url: '/shop/product/info/save',
    method: 'post',
    data: parameter
  })
}

export function delShopProductInfo (parameter) {
  return axios({
    url: '/shop/product/info/del',
    method: 'post',
    data: parameter
  })
}

export function saveShopCategoryInfo (parameter) {
  return axios({
    url: '/shop/product/category/save',
    method: 'post',
    data: parameter
  })
}

export function delShopCategoryInfo (parameter) {
  return axios({
    url: '/shop/product/category/del',
    method: 'post',
    data: parameter
  })
}

export function loadAlcDrinkLogList (parameter) {
  return axios({
    url: '/alc/drink/log/list',
    method: 'post',
    data: parameter
  })
}

export function saveProductSettingInfo (parameter) {
  return axios({
    url: '/shop/product/sort/info/save',
    method: 'post',
    data: parameter
  })
}

export function saveAdVideoSortInfo (parameter) {
  return axios({
    url: '/ad/sort/info/save',
    method: 'post',
    data: parameter
  })
}


export function update012UCalDataStatus (parameter) {
  return axios({
    url: '/012u/cal/data/status',
    method: 'post',
    data: parameter
  })
}

export function loadAcetoneExtraTestDataList (parameter) {
  return axios({
    url: '/user/extra/acetone/data/list',
    method: 'post',
    data: parameter
  })
}

export function load4GDeviceInfoList (parameter) {
  return axios({
    url: '/4g/device/info/list',
    method: 'post',
    data: parameter
  })
}

export function set4GDeviceAlertTag (parameter) {
  return axios({
    url: '/4g/device/alert/set',
    method: 'post',
    data: parameter
  })
}

export function save4GDeviceSetting (parameter) {
  return axios({
    url: '/4g/device/setting/save',
    method: 'post',
    data: parameter
  })
}

export function setKeyValue (parameter) {
  return axios({
    url: '/system/key/value/set',
    method: 'post',
    data: parameter
  })
}

export function getKeyValue (parameter) {
  return axios({
    url: '/system/key/value/load',
    method: 'post',
    data: parameter
  })
}
