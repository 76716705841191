<template>
  <div id="userLayout" :class="['user-layout-wrapper', device]">
    <div class="container" :style="{height:screenHeight+'px'}">
      <div class="top">
        <div class="desc">
        </div>
      </div>
      <div>
        <route-view></route-view>
      </div>

      <div class="footer" v-if="getI18nLanguage()==='zh-CN'">
        <div class="copyright">
          <a href="https://beian.miit.gov.cn" class="copyright-link">桂ICP备2024043074号</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteView from './RouteView'
import { mixinDevice } from '@/utils/mixin'
import { getI18nLanguage } from '@/locales'

export default {
  name: 'UserLayout',
  methods: { getI18nLanguage },
  components: { RouteView },
  mixins: [mixinDevice],
  data () {
    return {
      screenHeight: 0
    }
  },
  created () {
    this.screenHeight = document.body.clientHeight
  },
  mounted () {
    document.body.classList.add('userLayout')
  },
  beforeDestroy () {
    document.body.classList.remove('userLayout')
  }
}
</script>

<style lang="less" scoped>

.copyright-link{
  color:white;
}

.copyright-link:hover{
  color:#008df6;
}
  #userLayout.user-layout-wrapper {
    height: 100%;

    &.mobile {
      .container {
        .main {
          max-width: 368px;
          width: 98%;
        }
      }
    }

    .container {
      width: 100%;
      background:url(../assets/login-bg.jpg) no-repeat;
      background-size: cover;
      padding: 110px 0 144px;
      position: relative;

      a {
        text-decoration: none;
      }

      .top {
        text-align: center;

        .header {
          height: 44px;
          line-height: 44px;

          .badge {
            position: absolute;
            display: inline-block;
            line-height: 1;
            vertical-align: middle;
            margin-left: -12px;
            margin-top: -10px;
            opacity: 0.8;
          }

          .logo {
            height: 44px;
            vertical-align: top;
            margin-right: 16px;
            border-style: none;
            color:white;
          }

          .title {
            font-size: 33px;
            color: rgba(0, 0, 0, .85);
            font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
            font-weight: 600;
            position: relative;
            top: 2px;
          }
        }
        .desc {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          margin-top: 12px;
          margin-bottom: 40px;
        }
      }

      .main {
        min-width: 260px;
        width: 368px;
        margin: 0 auto;
      }

      .footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 0 16px;
        margin: 48px 0 24px;
        text-align: center;

        .links {
          margin-bottom: 8px;
          font-size: 14px;
          a {
            color: rgba(0, 0, 0, 0.45);
            transition: all 0.3s;
            &:not(:last-child) {
              margin-right: 40px;
            }
          }
        }
        .copyright {
          color: rgba(0, 0, 0, 0.45);
          font-size: 14px;
        }
      }
    }
  }
</style>
