<template>
  <div class="user-wrapper">
    <div class="content-box">
      <a href="https://doc.relaxcert.com" target="_blank"><i class="iconfont icon-wendang"></i>{{ $t('user.document') }}</a>
      <a-divider type="vertical" style="color: #999;"/>
      <a @click="feedback"><i class="iconfont icon-yijianfankui"></i>{{ $t('user.feedback') }}</a>
      <a-divider type="vertical" style="color: #999;"/>
      <a-popover>
        <template slot="content">
          <img src="/images/kefu_qr.png" style="width: 260px;" v-if="inChina">
          <div v-else>
            <img src="/images/mail.jpg" style="width: 320px;height:240px;">
            <div style="width: 320px;">
              <div>
                <div>{{ $t('customer.service.email') }}</div>
                <div><a href="mailto:support@relaxcert.com">support@relaxcert.com</a></div>
                <div>{{ $t('customer.service.email.reply.tip') }}</div>
              </div>

            </div>

          </div>
        </template>
        <a><i class="iconfont icon-kefu"></i>{{ $t('user.service') }}</a>
      </a-popover>

      <a-divider type="vertical" style="color: #999;"/>
      <!--      <a><i class="iconfont icon-shequ"></i>运维社区</a>-->
      <!--      <a-divider type="vertical" style="color: #999;"/>-->
      <a><i class="iconfont icon-app"></i>{{ $t('user.app.download') }}</a>
      <a-divider type="vertical" style="color: #999;"/>
      <!--      <a><i class="iconfont icon-geshizhuanhuan"></i>{{ $t('user.cert.tool.convert') }}</a>-->
      <!--      <a-divider type="vertical" style="color: #999;"/>-->
      <a-dropdown>
        <span class="action ant-dropdown-link user-dropdown-menu">
          <a-avatar class="avatar" size="middle" :src="avatar()"/>
          <span style="color: white;position:relative;top: 2px;">{{ nickname() }}</span>
        </span>
        <div slot="overlay" class="user-account-overlay ant-card">
          <div style="background-color: #F0F0F0;padding: 16px 8px;display: flex;">
            <div class="head-container">
              <a-avatar class="avatar" size="large" :src="avatar()" style="position: relative;top:2px;"/>
            </div>
            <div style="padding: 0 8px;">
              <div>{{ nickname() }}</div>
              <div>{{ account() }}</div>
            </div>
          </div>
          <div class="menu-list">
            <div class="menu-item">
              <div class="menu-icon">
                <icon-font type="icon-yonghuxinxi1"/>
              </div>

              <div class="menu-label">{{ $t('user.account.info') }}</div>
            </div>
            <div class="menu-item">
              <div class="menu-icon">
                <icon-font type="icon-yaoqingyouli" />
              </div>
              <div class="menu-label">{{ $t('user.invite.registration') }}</div>
            </div>
          </div>
          <div style="padding: 8px;">
            <a-button icon="logout" style="width:100%" @click="handleLogout">
              {{ $t('user.sign.out') }}
            </a-button>
          </div>

        </div>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import { Icon } from 'ant-design-vue'
import NoticeIcon from '@/components/NoticeIcon'
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'

const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4657304_rc6g6vm9q7.js'
})

export default {
  name: 'UserMenu',
  data () {
    return {
      role: 0,
      inChina: this.inChina()
    }
  },
  components: {
    NoticeIcon,
    IconFont
  },
  created () {
    var role = Vue.ls.get('role')
    this.role = role
    var locInfo = window.localStorage.getItem('local')
    if (locInfo) {
      var locObj = JSON.parse(locInfo)
      this.cLocal = locObj.name
    }
  },
  methods: {
    ...mapActions(['Logout']),
    ...mapGetters(['nickname', 'avatar', 'account']),
    handleLogout () {
      const that = this

      this.$confirm({
        title: '提示',
        content: '真的要注销登录吗 ?',
        onOk () {
          return that.Logout({}).then(() => {
            window.location.reload()
          }).catch(err => {
            that.$message.error({
              title: '错误',
              description: err.message
            })
          })
        },
        onCancel () {
        }
      })
    },
    showUInfo () {
      this.$emit('showAccountModal')
    },
    feedback () {
      this.$emit('feedback')
    }
  }
}
</script>
<style lang="less" scoped>
.content-box a{
  color:white;
}

.content-box .iconfont{
  position: relative;
  top:1px;
  margin-right: 2px;
}

.user-account-overlay{
  width: 280px;
  background-color: #FFFFFF;
  position: relative;
  top:-4px;
  right: -4px;
  border: 1px solid #eaeaea;
}

.menu-list{
  .menu-item{
    line-height: 42px;
    padding: 0 8px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
  }

  .menu-item:hover{
    background-color: #F0F0F0;
    cursor: pointer;
  }

  .menu-label{
    margin-left: 6px;
  }

}
</style>
