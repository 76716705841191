// eslint-disable-next-line
import {UserLayout, BasicLayout, RouteView, BlankLayout, PageView} from '@/layouts'

export const asyncRouterMap = [

  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    children: [
      // dashboard
      {
        path: '/console',
        name: 'console',
        redirect: '/console/index',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: 'menu.home', keepAlive: true, icon: 'icon-zhuye', permission: ['home'] },
        children: [
          {
            path: '/console/index',
            name: 'Index',
            component: resolve => require(['@/views/cert/index'], resolve),
            meta: { title: 'menu.home', keepAlive: true, permission: ['home'] }
          }
        ]
      },
      {
        path: '/cert',
        name: 'cert',
        redirect: '/console/cert',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: 'menu.cert', keepAlive: true, icon: 'icon-SSLzhengshu_', permission: ['cert'] },
        children: [
          {
            path: '/console/cert',
            name: 'Cert',
            component: resolve => require(['@/views/cert/Cert'], resolve),
            meta: { title: 'menu.cert', keepAlive: true, permission: ['cert'] }
          }
        ]
      },
      {
        path: '/cloud',
        name: 'cloud',
        redirect: '/console/cloud',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: 'menu.cloud', keepAlive: true, icon: 'icon-huaban', permission: ['cert'] },
        children: [
          {
            path: '/console/cloud',
            name: 'Cloud',
            component: resolve => require(['@/views/cert/Cloud'], resolve),
            meta: { title: 'menu.cloud', keepAlive: true, permission: ['cert'] }
          }
        ]
      },
      {
        path: '/domain',
        name: 'domain',
        redirect: '/console/domain',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: 'menu.monitoring', keepAlive: true, icon: 'icon-wangzhanyunhangjiance', permission: ['domain'] },
        children: [
          {
            path: '/console/domain',
            name: 'Domain',
            component: resolve => require(['@/views/cert/Domain'], resolve),
            meta: { title: 'menu.monitoring', keepAlive: true, permission: ['domain'] }
          }
        ]
      },
      // {
      //   path: '/event',
      //   name: 'event',
      //   redirect: '/console/event',
      //   component: RouteView,
      //   hideChildrenInMenu: true,
      //   meta: { title: 'menu.event.push', keepAlive: true, icon: 'icon-tuisong', permission: ['domain'] },
      //   children: [
      //     {
      //       path: '/console/event',
      //       name: 'Event',
      //       component: resolve => require(['@/views/cert/Event'], resolve),
      //       meta: { title: 'menu.monitoring', keepAlive: true, permission: ['domain'] }
      //     }
      //   ]
      // },
      {
        path: '/point',
        name: 'point',
        redirect: '/console/point',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: 'menu.score', keepAlive: true, icon: 'icon-jifendingdan', permission: ['domain'] },
        children: [
          {
            path: '/console/point',
            name: 'Score',
            component: resolve => require(['@/views/cert/Score'], resolve),
            meta: { title: 'menu.score', keepAlive: true, permission: ['domain'] }
          }
        ]
      },
      {
        path: '*', redirect: '/console', hidden: true
      }
    ],
    meta: { title: 'Home' },
    redirect: '/console'
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/u',
    component: UserLayout,
    redirect: '/u/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: resolve => require(['@/views/user/Login'], resolve)
      },
      {
        path: 'findpwd',
        name: 'findpwd',
        component: resolve => require(['@/views/user/ResetPassword'], resolve)
      },
      {
        path: 'register',
        name: 'register',
        component: resolve => require(['@/views/user/Register'], resolve)
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: resolve => require(['@/views/user/RegisterResult'], resolve)
      }
    ]
  },
  {
    path: '/404',
    component: resolve => require(['@/views/exception/404'], resolve)
  }

]
