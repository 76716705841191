
import { axios } from '@/utils/request'

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 * }
 * @param parameter
 * @returns {*}
 */
export function login (parameter) {
  return axios({
    url: '/user/login',
    method: 'post',
    data: parameter
  })
}

export function getInfo () {
  return axios({
    url: '/user/info/load',
    method: 'post',
    data: {}
  })
}

export function logout () {
  return axios({
    url: '/auth/logout',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getEmailCaptcha (parameter) {
  return axios({
    url: '/user/email/captcha/send',
    method: 'post',
    data: parameter
  })
}

export function reg (parameter) {
  return axios({
    url: '/user/reg',
    method: 'post',
    data: parameter
  })
}

export function forgetPassword (parameter) {
  return axios({
    url: '/user/password/forget',
    method: 'post',
    data: parameter
  })
}
