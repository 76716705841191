export default {
  'main.title': 'SSL自动续签助手',
  'home.home': '主页',
  'user.name': '姓名',
  'user.account.info': '账号基本信息',
  'user.name.required': '请输入姓名',
  'user.account': '邮箱',
  'user.email.required': '请输入您注册的邮箱',
  'user.password': '密码',
  'user.password.required': '请输入密码',
  'user.reg': '注册',
  'user.reg.success': '注册成功',
  'user.reg.fail': '注册失败',
  'user.reg.return': '即将返回登录页面',
  'user.reg.new.account': '注册新账户',
  'user.regnow': '立即注册',
  'user.login': '登录',
  'user.sign.out': '退出登录',
  'user.captcha': '验证码',
  'user.captcha.required': '请输入验证码',
  'user.signInGoogle': '使用Google登录',
  'user.emailCaptcha': '邮箱验证码',
  'user.getCaptcha': '获取验证码',
  'user.request.network.error': '请求出现错误，请稍后再试。',
  'user.password.change.password.current': '当前密码',
  'user.password.change.password.new': '新密码',
  'user.password.change.password.new.tip': '请输入新密码',
  'user.password.change.password.current.tip': '请输入当前密码',
  'user.password.change.passwordReview.new': '确认新密码',
  'user.password.change.passwordReview.new.tip': '请再次输入相同的新密码',
  'user.password.change.password.format.error': '密码必须为字母数字混合，长度为8-20',
  'user.password.level.strength': '强度：',
  'user.password.level.low': '低',
  'user.password.level.medium': '中',
  'user.password.level.high': '高',
  'user.password.level.error': '密码强度不够。',
  'user.password.level.tip': ' 请至少输入 8 个字符。请不要使用容易被猜到的密码。',
  'user.password.not.match': '两次密码不一致',
  'user.account.required': '请输入邮箱地址',
  'user.account.login': '使用已有账户登录',
  'user.password.format.tip': '至少8位密码，区分大小写。',
  'user.email.captcha.send': '验证码发送中..',
  'user.email.captcha.send.success': '验证码发送成功，请注意查收邮件。',
  'user.readAndAgree': '我已阅读并同意',
  'user.UserServiceAgreement': '《用户服务协议》',
  'user.action.delete': '删除',
  'user.action.edit': '编辑',
  'user.action.more': '更多操作',
  'user.cert.recheck': '重新检测证书',
  'user.website.jump': '跳转站点',
  'user.website.unreachable': '无法访问',
  'user.website.unknown': '未知',
  'user.website.normal': '正常',
  'user.website.certExpiration': '证书过期',
  'user.website.notgen': '非本站签发',
  'user.website.add.time': '添加时间',
  'cloud.provider': '云供应商',
  'cloud.provider.required': '请选择云供应商',
  'cloud.provider.aliyun': '阿里云',
  'cloud.provider.baiduyun': '百度云',
  'cloud.provider.tencentcloud': '腾讯云',
  'cloud.provider.google': '谷歌云',
  'cloud.provider.aws': '亚马逊云',
  'cloud.provider.dianxin': '天翼云',
  'cloud.service.general': '通用',
  'cloud.add.time': '添加时间',
  'cloud.domain': '域名',
  'cloud.remark': '备注',
  'cloud.service.product': '服务产品',
  'cloud.service.product.required': '请选择服务产品',
  'cloud.service.product.config': '添加云服务节点',
  'menu.home': '主页',
  'menu.cert': '网站证书',
  'menu.cloud': '云服务证书',
  'menu.monitoring': '站点监控',
  'menu.score': '积分管理',
  'menu.title': 'SSL自动续签',
  'user.cert.unit': '设置单位信息',
  'user.cert.reissueCert': '新签发证书',
  'user.code.copy': '点击复制命令',
  'user.cert.download': '下载证书',
  'user.cert.editNode': '编辑云服务节点信息',
  'user.website.cert.invalid': '已过期',
  'menu.event.push': '事件推送',
  'customer.service.email': '如果您需要任何帮助，请发送邮件至一下邮箱：',
  'customer.service.email.reply.tip': 'The administrator will reply to your email immediately upon seeing it, usually within 16 hours.',
  'user.document': '文档',
  'user.feedback': '反馈建议',
  'user.feedback.type': '反馈类型',
  'user.feedback.type.suggestion': '系统优化建议',
  'user.feedback.type.bug': '系统问题反馈',
  'user.feedback.type.requirement': '新的功能需求',
  'user.action': '操作',
  'user.service': '客服支持',
  'user.cert.tool.convert': '证书格式转换工具',
  'user.app.download': '移动端程序',
  'cert.validity.period': '证书有效期',
  'cert.dns.config': 'DNS配置',
  'cert.domain': '域名',
  'cert.remark': '备注',
  'cert.unit.name': '组织名称',
  'cert.unit.name.edit': '设置组织名称',
  'cert.unit.name.tip': '设定的组织名称展示效果示例如下图：',
  'cert.unit.name.tip2': '注意：设定组织名称后需要重新签发证书才能生效。',
  'user.password.change.password': '重置密码',
  'user.password.change.password.tip': '请输入密码',
  'user.password.change.passwordReview': '确认密码',
  'user.password.change.password.not.match': '两次输入密码不一致',
  'user.password.forget': '忘记密码？',
  'user.password.reset.title': '重置登录密码',
  'user.password.reset': '重置密码',
  'user.password.reset.success': '重置密码成功',
  'user.action.ok': '确认',
  'user.password.change.ok': '保存',
  'user.password.change.cancel': '取消',
  'user.welcome': '欢迎',
  'user.welcome.back': '，欢迎回来。',
  'user.good.morning': '早上好',
  'user.good.noon': '中午好',
  'user.good.afternoon': '下午好',
  'user.good.evening': '晚上好',
  'user.support.email.subject': '邮箱主题请填写：',
  'user.support.email.subject.content': 'RelaxCert证书续签助手',
  'user.support.email.body': '正文填写说明：',
  'user.support.email.body.content1': '1.如果您想反馈系统问题，请将问题描述写在正文，必要时可添加截图。',
  'user.support.email.body.content2': '2.如果您想提交新的需求，请将需求写在正文。若为第三方系统对接需求，请写明第三方系统的官网地址或者文档地址。',
  'website.domain': '域名',
  'website.ip': 'IP地址',
  'website.port': '端口',
  'website.status': '状态',
  'website.remark': '备注',
  'website.monitoring.speed.upgrade': '提升监控频率',
  'website.action.delete': '批量删除站点',
  'website.access.status': '站点访问状态',
  'website.access.status.stat': '站点访问状态统计',
  'website.cert.status.stat': '站点证书状态统计',
  'user.feedback.email': '答复邮箱',
  'user.feedback.email.tip': '管理员将通过该邮箱答复您。',
  'user.feedback.content': '反馈内容',
  'user.feedback.title': '反馈标题',
  'user.login.info.request.error': '请求用户信息失败，请重试',
  'user.feedback.screenshots': '相关截图',
  'table.emptyText': '暂无数据',
  'table.record.total.num': '共{0}条数据',
  'index.banner.title1': '一键操作，全程自动化',
  'index.banner.title2': '实时监控，即时提醒',
  'index.banner.title3': '多平台兼容，广泛适用',
  'index.banner.title4': '基础用户，完全免费',
  'index.banner.content1': '告别繁琐的手动续签流程，自动化证书续签助手采用先进的智能算法，自动监测您网站SSL证书的有效期。一旦接近过期，立即启动续签流程，无需人工干预，从申请到安装，全程自动化完成，确保您的网站安全无缝衔接。',
  'index.banner.content2': '实时监控与即时提醒服务。通过邮件、短信等多种方式，第一时间通知您证书状态变化，确保您随时掌握网站安全动态，做到心中有数，防患未然。',
  'index.banner.content3': '支持各大云厂商的OSS、CDN、DCDN业务，自动化证书续签助手都能完美兼容，为您的网站安全保驾护航。',
  'index.banner.content4': '注册即送50积分，可完成自动部署5次。积分可通过各种方式获取，无需用户实际付费。',
  'user.notification.new': '最新公告',
  'user.dynamic.message': '最新动态',
  'user.point.buy': '购买积分',
  'user.point.current': '当前积分：',
  'user.point.record': '积分记录',
  'user.point.rule': '积分规则',
  'user.point.tip': '您可通过积分规则中的多种途径免费获取积分，也可通过购买积分的方式快速获取积分。购买价格为0.6元每积分。',
  'user.point.reward.rule': '积分奖励规则',
  'user.point.deduction.rule': '积分扣除规则',
  'user.point.variable': '变动积分',
  'user.point.left': '剩余积分',
  'user.point.change.desc': '描述',
  'user.point.change.time': '时间',
  'server.linux.nginx.tip': '针对Linux Nginx服务器，复制以下代码运行，自动申请和续签SSL证书。',
  'server.linux.nginx.tip2': '以上命令最后一个参数为您的专属密钥，不要泄露给他人。若您发现该密钥已泄露，您可以',
  'server.linux.nginx.key.update': '更新秘钥。',
  'server.windows.iis.tip': '针对Windows Server IIS服务器，下载如下exe程序，自动申请和续签SSL证书。',
  'server.windows.iis.32.download': '下载32位程序',
  'server.windows.iis.64.download': '下载64位程序',
  'client.type': '客户端',
  'user.point.charge.point': '积分',
  'user.point.charge.custom': '自定义',
  'user.point.buy.submit': '使用PayPal付款',
  'user.point.buy.pay': '您需要支付',
  'user.point.task.sign-in': '签到',
  'user.point.task.sign-in.remark': '连续签到1-3天每天奖励1积分，连续签到4天及以上每天奖励2积分。',
  'user.point.task.reg': '注册新用户',
  'user.point.task.reg.tip': '注册后奖励积分。',
  'user.point.task.visit': '邀请他人注册',
  'user.point.task.visit.tip': '点击右上角账户选择邀请注册复制邀请链接，被邀请人注册后奖励30积分。',
  'user.point.task.feedback': '反馈系统问题或者提出合理化建议',
  'user.point.task.feedback.tip': '在上方反馈建议处提交记录，管理员确认后奖励积分。',
  'user.point.task.docking': '协助管理员接入新的与证书有关的云服务平台',
  'user.point.task.docking.tip': '新的云服务平台接入请在上方点击反馈建议，反馈类型选择新的功能需求。说明中请注明云服务平台的官网地址或者文档地址',
  'user.point.task.ad': '观看广告视频',
  'user.point.task.ad.tip': '观看完毕后奖励2积分。限制一小时内仅能观看一次。',
  'user.point.function.renew.auto': '自动续签证书',
  'user.point.function.renew.manual': '手工签发单域名证书',
  'user.point.function.renew.auto.tip': '按域名每次自动续签（约75-90天每次)扣除积分。相同域名下的子域名使用泛域名证书时不重复扣除积分。若某个子域名使用单域名证书，则单独扣除积分。',
  'user.point.function.renew.manual.tip': '添加域名并成功签发证书时扣除积分。',
  'cloud.dns.config': 'DNS解析配置',
  'cloud.domain.renew.option': '自动部署选项',
  'cloud.api.auth': 'API权限配置',
  'cloud.action.next': '下一步',
  'cloud.action.previous': '上一步',
  'cloud.config.name': '名称',
  'cloud.config.name.tip': '请输入一个便于区分不同服务的名称。',
  'cloud.action.save': '保存',
  'cloud.cert.auto.renew.enable': '自动部署证书',
  'cloud.cert.auto.renew.on': '开启',
  'cloud.cert.auto.renew.off': '关闭',
  'domain.dns.domain': '域名',
  'domain.dns.domain.not.found': '未查询到域名信息，请检查授权信息或权限设置。',
  'cloud.cert.auto.renew.enable.none': '请至少开启一项自动部署才能进入下一步',
  'domain.dns.host.record': '主机记录',
  'domain.dns.record.type': '记录类型',
  'domain.dns.record.value': '记录值',
  'user.invite.registration': '邀请注册领积分',
  'cloud.domain.enable.one': '请至少开启一项自动部署才能进入下一步。',
  'user.action.config.save': '保存配置',
  'cloud.dns.config.check': '重新校验',
  'cloud.dns.config.check.success': '校验通过',
  'cloud.dns.config.check.fail': '校验未通过',
  'cloud.dns.config.check.error': '请求错误',
  'cloud.domain.dns.config.tip': '请按上表配置域名DNS解析记录。',
  'cert.provider': '证书供应商',
  'user.point.item': '项目',
  'user.point.task.item': '任务项',
  'user.point.task.client': '客户端',
  'user.point.task.point': '积分',
  'user.point.task.remark': '备注',

  'user.action.save': '保存',
  'user.action.cancel': '取消',
  'user.point.buy.num.tip': '请选择或输入您的积分充值点数。',
  'user.action.submit': '提交',
  'function.come.soon': '正在开发中，预计11月中旬上线。',
  'cert.status': '证书状态',
  'cert.status.normal': '正常',
  'cert.status.expiring.soon': '即将过期',
  'cert.status.expired': '已过期',
  'cert.status.invalid': '无效',
  'cert.action.delete.tip': '删除证书后，再次申请该域名证书会再次扣除积分。确认删除该证书吗？'
}
