<template>
  <div>
    <a-layout :class="['layout', device]">
      <!-- SideMenu -->
      <a-drawer
        v-if="isMobile()"
        placement="left"
        :wrapClassName="`drawer-sider ${navTheme}`"
        :closable="false"
        :visible="collapsed"
        @close="drawerClose"
      >
        <side-menu
          mode="inline"
          :menus="menus"
          :theme="navTheme"
          :collapsed="false"
          :collapsible="true"
          @menuSelect="menuSelect"
        ></side-menu>
      </a-drawer>

      <side-menu
        v-else-if="isSideMenu()"
        mode="inline"
        :menus="menus"
        :theme="navTheme"
        :collapsed="collapsed"
        :collapsible="true"
      ></side-menu>

      <a-layout
        :class="[layoutMode, `content-width-${contentWidth}`]"
        :style="{ paddingLeft: contentPaddingLeft, minHeight: '100vh' }">
        <!-- layout header -->
        <global-header
          :mode="layoutMode"
          :menus="menus"
          :theme="navTheme"
          :collapsed="collapsed"
          :device="device"
          @toggle="toggle"
          @showAccountModal="showAccountModal"
        />

        <!-- layout content -->
        <a-layout-content
          :style="{ height: '100%', margin: multiTab ? '24px 24px 0' : '4px 0 0  6px', paddingTop: fixedHeader ? '64px' : '0' }">
          <multi-tab v-if="multiTab"></multi-tab>
          <transition name="page-transition">
            <route-view/>
          </transition>
        </a-layout-content>

        <!-- layout footer -->
        <a-layout-footer>
          <global-footer/>
        </a-layout-footer>

        <!-- Setting Drawer (show in development mode) -->
        <setting-drawer v-if="!production"></setting-drawer>
      </a-layout>
    </a-layout>
    <a-modal
      title="账户设置"
      :visible="accountModalShow"
      @cancel="cancelAccount"
      @ok="saveUinfo">
      <div>
        <div style="color:#999;">为了您的账户安全，请重置密码。</div>
        <div style="margin-top: 12px;">
          <a-row>
            <a-col span="4"><div style="line-height: 36px;padding-right: 8px;" >重置密码</div></a-col>
            <a-col span="20"> <a-input type="password" v-model="accountInfo.pwd"></a-input></a-col>
          </a-row>
          <a-row style="margin-top: 10px;">
            <a-col span="4"><div style="line-height: 36px;padding-right: 8px;">确认密码</div></a-col>
            <a-col span="20"> <a-input type="password" v-model="accountInfo.pwdReview"></a-input></a-col>
          </a-row>
        </div>

        <!--<a-row>-->
        <!--<a-col  span="4"><div>手机号</div></a-col>-->
        <!--<a-col  span="20"> <a-input></a-input></a-col>-->
        <!--</a-row>-->
      </div>
    </a-modal>
  </div>
</template>

<script>
import { triggerWindowResizeEvent } from '@/utils/util'
import { mapState, mapActions } from 'vuex'
import { mixin, mixinDevice } from '@/utils/mixin'
import config from '@/config/defaultSettings'
import Vue from 'vue'
import RouteView from './RouteView'
import MultiTab from '@/components/MultiTab'
import SideMenu from '@/components/Menu/SideMenu'
import GlobalHeader from '@/components/GlobalHeader'
import GlobalFooter from '@/components/GlobalFooter'
import SettingDrawer from '@/components/SettingDrawer'
import { Modal, Radio } from 'ant-design-vue'
import { resetInfo } from '@/api/data'
import md5 from 'md5'
import { asyncRouterMap } from '@/config/router.config.js'

export default {
  name: 'BasicLayout',
  mixins: [mixin, mixinDevice],
  components: {
    RouteView,
    MultiTab,
    SideMenu,
    GlobalHeader,
    GlobalFooter,
    SettingDrawer,
    Modal,
    Radio
  },
  data () {
    return {
      accountModalShow: false,
      production: config.production,
      collapsed: false,
      menus: [],
      loading: false,
      visible: false,
      lastOpenFinish: 0,
      accountInfo: {}
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: state => state.permission.addRouters

    }),
    contentPaddingLeft () {
      if (!this.fixSidebar || this.isMobile()) {
        return '0'
      }
      if (this.sidebarOpened) {
        return '256px'
      }
      return '80px'
    }
  },
  watch: {
    sidebarOpened (val) {
      this.collapsed = !val
    }
  },
  created () {
    this.menus = asyncRouterMap.find((item) => item.path === '/').children
    // this.menus = this.mainMenu.find((item) => item.path === '/').children
    this.collapsed = !this.sidebarOpened
    this.accountModalShow = (Vue.ls.get('RESETTAG') === 1)
  },
  mounted () {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }
  },
  methods: {
    ...mapActions(['setSidebar', 'Logout']),
    toggle () {
      this.collapsed = !this.collapsed
      this.setSidebar(!this.collapsed)
      triggerWindowResizeEvent()
    },
    paddingCalc () {
      let left = ''
      if (this.sidebarOpened) {
        left = this.isDesktop() ? '256px' : '80px'
      } else {
        left = (this.isMobile() && '0') || ((this.fixSidebar && '80px') || '0')
      }
      return left
    },
    menuSelect () {
      if (!this.isDesktop()) {
        this.collapsed = false
      }
    },
    drawerClose () {
      this.collapsed = false
    },
    handleOk () {

    },
    handleCancel () {

    },
    showAccountModal () {
      this.accountModalShow = true
    },
    cancelAccount () {
      this.accountInfo = {}
      this.accountModalShow = false
    },
    saveUinfo () {
      if (!this.accountInfo.pwd || this.accountInfo.pwd.length === 0) {
        this.$message.error('请输入密码')
        return false
      }
      if (this.accountInfo.pwd.length < 8) {
        this.$message.error('密码至少8位字符')
        return false
      }
      if (!this.accountInfo.pwdReview || this.accountInfo.pwd !== this.accountInfo.pwdReview) {
        this.$message.error('密码不一致')
        return false
      }
      resetInfo({ pwd: md5(this.accountInfo.pwd) }).then(res => {
        if (res.code === 0) {
          this.accountModalShow = false
          var that = this
          setTimeout(function () {
            that.Logout({}).then(() => {
              window.location.reload()
            }).catch(err => {
              that.$message.error({
                title: '错误',
                description: err.message
              })
            })
          }, 500)
        }
      })
    }
  }
}
</script>

<style lang="less">
  @import url('../components/global.less');

  /*
   * The following styles are auto-applied to elements with
   * transition="page-transition" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the page transition by editing
   * these styles.
   */

  .page-transition-enter {
    opacity: 0;
  }

  .page-transition-leave-active {
    opacity: 0;
  }

  .page-transition-enter .page-transition-container,
  .page-transition-leave-active .page-transition-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .form-item{
    display: flex;
    line-height: 36px;

  }

  .modify-lable{
    width: 70px;
  }

  .modify-content{
    flex: 1;
    padding-left: 8px;
  }
</style>
