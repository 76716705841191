<script>
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'Feedback',
  data () {
    return {
      fileList: [],
      previewVisible: false,
      previewImage: '',
      form: this.$form.createForm(this)
    }
  },
  methods: {
    handleCancel () {
      this.previewVisible = false
    },
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange ({ fileList }) {
      this.fileList = fileList
    },
    handleSubmit (e) {

    }
  }
}
</script>

<template>
  <div style="width: 100%;padding: 8px;">
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }" @submit="handleSubmit">
      <a-form-item :label="$t('user.feedback.email')">
        <a-input v-decorator="['email', { rules: [{ required: true, message: 'Please input your note!' }] }]"></a-input>
      </a-form-item>
      <a-form-item :label="$t('user.feedback.type')">
        <a-select v-decorator="['type', { rules: [{ required: true, message: 'Please input your note!' }] }]">
          <a-select-option value="suggestion">{{ $t('user.feedback.type.suggestion') }}</a-select-option>
          <a-select-option value="bug">{{ $t('user.feedback.type.bug') }}</a-select-option>
          <a-select-option value="requirement">{{ $t('user.feedback.type.requirement') }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('user.feedback.title')">
        <a-input v-decorator="['title', { rules: [{ required: true, message: 'Please input your note!' }] }]"></a-input>
      </a-form-item>
      <a-form-item :label="$t('user.feedback.content')">
        <a-textarea style="height:180px;" v-decorator="['content', { rules: [{ required: true, message: 'Please input your note!' }] }]"></a-textarea>
      </a-form-item>
      <a-form-item :label="$t('user.feedback.screenshots')">
        <div class="clearfix">
          <a-upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            list-type="picture-card"
            :file-list="fileList"
            @preview="handlePreview"
            @change="handleChange"
          >
            <div v-if="fileList.length < 8">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                Upload
              </div>
            </div>
          </a-upload>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </div>
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 6 }">
        <a-button type="primary">{{ $t('user.action.submit') }}</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<style scoped>

</style>
