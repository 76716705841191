
export default {
  'main.title': 'SSL Automatic Renewal Assistant',
  'home.home': 'Home',
  'user.name': 'Name',
  'user.name.required': 'Please enter your name.',
  'user.account': 'Email',
  'user.account.info': 'Basic Account Information',
  'user.email.required': 'Please enter your registered email address',
  'user.password': 'Password',
  'user.password.required': 'Please enter your login password.',
  'user.password.reset.title': 'Reset your password',
  'user.reg': 'Sign up',
  'user.reg.success': 'Sign up successfully',
  'user.reg.fail': 'Sign up has failed',
  'user.password.reset.success': 'Password reset successful',
  'user.reg.return': 'Coming back to the sign in page soon',
  'user.reg.new.account': 'Sign up  a new account',
  'user.regnow': 'Sign up now',
  'user.login': 'Sign in',
  'user.sign.out': 'Sign out',
  'user.captcha': 'Captcha',
  'user.captcha.required': 'Please enter the verification code',
  'user.signInGoogle': 'Sign in with Google',
  'user.emailCaptcha': 'Email Captcha',
  'user.getCaptcha': 'Get Captcha',
  'user.readAndAgree': 'I have read and agree the ',
  'user.UserServiceAgreement': 'User Service Protocol',
  'user.request.network.error': 'There was an error with the request, please try again later.',
  'user.action.delete': 'Delete',
  'user.action.edit': 'Edit',
  'user.action.more': 'More actions',
  'user.cert.recheck': 'Recheck cert',
  'user.website.jump': 'Jump to website',
  'user.website.unreachable': 'unreachable',
  'user.website.unknown': 'unknown',
  'user.website.normal': 'normal',
  'user.website.certExpiration': 'cert expired',
  'user.website.notgen': 'Issued by other',
  'user.password.change.password.current': '当前密码',
  'user.password.change.password.new': '新密码',
  'user.password.change.password.new.tip': '请输入新密码',
  'user.password.change.password.current.tip': '请输入当前密码',
  'user.password.change.password': '重置密码',
  'user.password.change.password.tip': 'Please input a password.',
  'user.password.change.passwordReview': 'Password again',
  'user.password.change.password.not.match': 'The password entered twice is inconsistent.',
  'user.password.change.passwordReview.new': 'New password again',
  'user.password.change.passwordReview.new.tip': 'Please enter the same new password again',
  'user.password.change.password.format.error': 'The password must be a mixture of letters and numbers, with a length of 8-20.',
  'user.password.level.strength': 'Strength:',
  'user.password.level.low': 'low',
  'user.password.level.medium': 'medium',
  'user.password.level.high': 'high',
  'user.password.level.error': 'Insufficient password strength.',
  'user.password.level.tip': 'Please enter at least 8 characters. Please do not use passwords that are easily guessed.',
  'user.password.not.match': 'Password inconsistency',
  'user.account.required': 'Please enter your email address',
  'user.account.login': 'Sign in now',
  'user.password.forget': 'Forgot password?',
  'user.password.reset': 'Reset password ',
  'user.action.ok': 'Confirm',
  'user.password.format.tip': 'At least 8-digit password, case sensitive.',
  'user.email.captcha.send': 'Verification code is being sent',
  'user.email.captcha.send.success': 'The verification code has been successfully sent. Please check your email carefully.',
  'menu.home': 'Home',
  'menu.cert': 'Website Certificate',
  'menu.cloud': 'Cloud Certificate',
  'menu.monitoring': 'Site Monitoring',
  'menu.score': 'Points Management',
  'menu.title': 'SSL auto renew',
  'user.action': 'Action',
  'user.cert.unit': 'Set unit information',
  'user.cert.reissueCert': 'Reissue cert',
  'user.code.copy': 'Click to copy code.',
  'user.cert.download': 'Download cert',
  'user.cert.editNode': 'Edit cloud service node information',
  'user.website.cert.invalid': 'invalid',
  'user.website.add.time': 'Added time',
  'menu.event.push': 'Event Push',
  'customer.service.email': 'If you need any assistance, please send an email to the following email address:',
  'customer.service.email.reply.tip': 'The administrator will reply to your email immediately upon seeing it, usually within 16 hours.',
  'user.document': 'Document',
  'user.feedback': 'Feedback',
  'user.service': 'Support',
  'user.cert.tool.convert': 'Certificate Format Conversion Tool',
  'user.app.download': 'APP Download',
  'cloud.provider': 'Cloud Provider',
  'cloud.provider.required': 'Please choose a cloud provider.',
  'cloud.provider.aliyun': 'Alibaba Cloud',
  'cloud.provider.baiduyun': 'BAIDU AI CLOUD',
  'cloud.provider.tencentcloud': 'Tencent Cloud',
  'cloud.provider.google': 'Google Cloud',
  'cloud.provider.aws': 'Amazon Web Services',
  'cloud.service.general': 'General',
  'cloud.add.time': 'Added Time',
  'cloud.domain': 'Domain',
  'cloud.remark': 'Remark',
  'cloud.service.product': 'Service Product',
  'cloud.service.product.required': 'Please select a service product.',
  'cloud.service.product.config': 'Config New Service Product',
  'cert.validity.period': 'Validity period',
  'cert.dns.config': 'DNS Configuration',
  'cert.remark': 'Remark',
  'cert.domain': 'Domain',
  'cert.add.time': 'Added time',
  'cert.unit.name': 'Organization',
  'cert.unit.name.edit': 'Set organization name',
  'cert.unit.name.tip': 'An example of the display effect of the set organization name is shown in the following figure.',
  'cert.unit.name.tip2': 'Note: After setting the organization name, a new certificate needs to be issued to take effect.',
  'user.welcome': 'Welcome',
  'user.welcome.back': ',welcome back.',
  'user.good.morning': 'Good morning',
  'user.good.noon': 'Good noon',
  'user.good.afternoon': 'Good afternoon',
  'user.good.evening': 'Good evening',
  'user.support.email.subject': 'Email subject:',
  'user.support.email.subject.content': 'RelaxCert Certificate Renewal Assistant',
  'user.support.email.body': 'Instructions for filling in the body:',
  'user.support.email.body.content1': '1.If you would like to provide feedback on a system issue, please write the problem description in the main text and add screenshots if necessary.',
  'user.support.email.body.content2': '2.If you want to submit a new requirement, please write it in the body. If it is a requirement for third-party system integration, please specify the official website address or document address of the third-party system.',
  'website.add': 'Add Domain',
  'website.domain': 'Domain',
  'website.ip': 'IP',
  'website.port': 'Port',
  'website.status': 'Status',
  'website.remark': 'Remark',
  'website.action.delete': 'Delete WebSite',
  'website.monitoring.speed.upgrade': 'Increase Monitoring Frequency',
  'website.access.status': 'Site access status',
  'website.access.status.stat': 'Site access status statistics',
  'website.cert.status.stat': 'Site certificate status statistics',
  'user.feedback.type': 'Feedback Type',
  'user.feedback.type.suggestion': 'Optimization suggestion',
  'user.feedback.type.bug': 'Bug feedback',
  'user.feedback.type.requirement': 'New requirement',
  'user.feedback.email': 'Reply Email',
  'user.feedback.email.tip': 'Please enter your email address',
  'user.feedback.content': 'Feedback Content',
  'user.feedback.title': 'Feedback Title',
  'user.feedback.screenshots': 'Related Screenshots',
  'table.emptyText': 'No data',
  'table.record.total.num': 'Total {0} item(s)',
  'index.banner.title1': 'Simple operation, fully automated throughout the process.',
  'index.banner.title2': 'Real time monitoring and instant reminders。',
  'index.banner.title3': 'Multi platform compatibility, widely applicable.',
  'index.banner.title4': 'Basic users can use it for free',
  'index.banner.content1': 'Say goodbye to the tedious manual renewal process, the automated certificate renewal assistant adopts advanced intelligent algorithms to automatically monitor the validity period of your website\'s SSL certificate. Once approaching expiration, immediately initiate the renewal process without manual intervention. From application to installation, the entire process is automated to ensure the security and seamless connection of your website.',
  'index.banner.content2': 'Real time monitoring and instant reminder services. By using various methods such as email and SMS, we will notify you of any changes in the certificate status as soon as possible, ensuring that you are always aware of the website\'s security updates and have a clear understanding to prevent any potential issues.',
  'index.banner.content3': 'Supporting certificate related services from major cloud service providers such as Amazon Web Services, Google Cloud, and Microsoft Azure, the automated certificate renewal assistant is perfectly compatible.',
  'index.banner.content4': 'By registering, you will receive 50 points and can complete automatic deployment 5 times. Points can be obtained through various methods without the need for users to actually pay.',
  'user.dynamic.message': 'Dynamic message',
  'user.notification.new': 'Latest Announcement',
  'user.point.charge.point': ' points',
  'user.point.charge.custom': 'custom',
  'user.point.buy.submit': 'Pay with PayPal',
  'user.point.buy.pay': 'You need to pay ',
  'user.point.buy': 'Purchase Points',
  'user.point.current': 'Current points:',
  'user.point.tip': 'You can obtain points for free through various channels in the points rules, or quickly obtain points by purchasing points.The payment unit price is  10 points/1 USD.',
  'user.point.record': 'Points Record',
  'user.point.rule': 'Points Rule',
  'user.point.reward.rule': 'Points Reward Rules',
  'user.point.deduction.rule': 'Points Deduction Rules',
  'user.point.variable': 'Variable points',
  'user.point.left': 'Remaining points',
  'user.point.change.desc': 'Describe',
  'user.point.change.time': 'Time',
  'user.point.function.renew.auto': 'Automatic certificate renewal',
  'user.point.function.renew.auto.tip': 'Automatically renew each domain name (approximately 75-90 days per renewal) and deduct points. When using a generic domain certificate for subdomains under the same domain name, points will not be deducted repeatedly. If a subdomain uses a single domain certificate, points will be deducted separately.',
  'user.point.function.renew.manual': 'Manually issue a single domain name certificate',
  'user.point.function.renew.manual.tip': 'Deducting points when adding a domain name and successfully issuing a certificate.',
  'server.linux.nginx.tip': 'For Linux Nginx server, copy the following code and run it to automatically apply for and renew SSL certificates.',
  'server.linux.nginx.tip2': 'The last parameter of the above command is your exclusive key, do not disclose it to others. If you find that the key has been leaked, you can ',
  'server.linux.nginx.key.update': ' update key.',
  'server.windows.iis.tip': 'For Windows Server IIS server, download the following exe program to automatically apply for and renew SSL certificates.',
  'server.windows.iis.32.download': 'Download 32-bit EXE program',
  'server.windows.iis.64.download': 'Download 64-bit EXE program',
  'client.type': 'Client',
  'client.type.web': 'PC WEB',
  'client.type.app': 'Mobile APP',
  'user.point.task.visit': 'Invite others to register',
  'user.point.task.sign-in': 'Sign in',
  'user.point.task.sign-in.remark': 'For consecutive sign ins of 1-3 days, 1 point will be awarded per day, and for consecutive sign ins of 4 days or more, 2 points will be awarded per day.',
  'user.point.task.reg': 'Register a new user',
  'user.point.task.reg.tip': 'Reward  points after registration.',
  'user.point.task.visit.tip': 'Click on the account in the upper right corner and select \'Invite Registration\' to copy the invitation link. The invitee will receive a reward of 30 points after registering.',
  'user.point.task.feedback': 'Provide feedback on system issues or offer rational suggestions',
  'user.point.task.feedback.tip': 'Submit a record in the feedback section above, and after confirmation by the administrator, reward  points each time.',
  'user.point.task.docking': 'Assist administrators in accessing new cloud service platforms related to certificates',
  'user.point.task.docking.tip': 'Please click on the feedback suggestion above to access the new cloud service platform, and select the new functional requirements as the feedback type. Please indicate the official website address or document address of the cloud service platform in the description.',
  'user.point.task.ad': 'Watch advertising videos',
  'user.point.task.ad.tip': 'Reward 2 points after watching. Restricted to only one viewing per hour.',
  'cloud.dns.config': 'DNS resolution configuration',
  'cloud.domain.renew.option': 'Automatic deployment options',
  'cloud.api.auth': 'API permission configuration',
  'cloud.action.next': 'Next',
  'cloud.action.previous': 'Previous',
  'cloud.action.save': 'Save',
  'cloud.config.name': 'Name',
  'cloud.cert.auto.renew.enable': 'Automatic deployment',
  'cloud.cert.auto.renew.on': 'ON',
  'cloud.cert.auto.renew.off': 'OFF',
  'cloud.config.name.tip': 'Please enter a name that is easy to distinguish between different services.',
  'domain.dns.domain': 'Domain',
  'domain.dns.domain.not.found': 'Domain name information not found, please check authorization information or permission settings.',
  'cloud.cert.auto.renew.enable.none': '请至少开启一项自动部署才能进入下一步',
  'domain.dns.host.record': 'Host record',
  'domain.dns.record.type': 'Record type',
  'domain.dns.record.value': 'Record value',
  'user.invite.registration': 'Invite new users to earn points',
  'cloud.domain.enable.one': 'Please enable at least one automatic deployment option.',
  'user.action.config.save': 'Save configuration',
  'cloud.dns.config.check': 'Re verify',
  'cloud.dns.config.check.success': 'Verification passed',
  'cloud.dns.config.check.fail': 'Verification failed',
  'cloud.dns.config.check.error': 'Request error',
  'cloud.domain.dns.config.tip': 'Please configure the domain name DNS resolution records according to the table above.',
  'cert.provider': 'Cert provider',
  'user.point.item': 'Items',
  'user.point.task.item': 'Task items',
  'user.point.task.client': 'Channel',
  'user.point.task.point': 'Points',
  'user.point.task.remark': 'Remarks',
  'user.action.save': 'Save',
  'user.action.cancel': 'Cancel',
  'user.point.buy.num.tip': 'Please select or enter your points for recharging.',
  'user.action.submit': 'Submit',
  'function.come.soon': 'Under development, expected to be launched in mid November.',
  'cert.status': 'Certificate status',
  'cert.status.normal': 'Normal',
  'cert.status.expiring.soon': 'Expiring soon',
  'cert.status.expired': 'Expired',
  'cert.status.invalid': 'Invalid',
  'cert.action.delete.tip': 'After deleting the certificate，applying for the domain certificate again will deduct points again. Are you sure to delete this certificate?'
}
