import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enUS from './locale/en-US.js'
import zhCN from './locale/zh-CN.js'
Vue.use(VueI18n)
export const defaultLang = navigator.language
const localStorage = window.localStorage
let mLang = localStorage.getItem('mLang')
if (!mLang) {
  mLang = defaultLang
  localStorage.setItem('mLang', mLang)
}
const i18n = new VueI18n({
  locale: mLang, // 语言标识
  fallbackLocale: 'en-US',
  messages: {
    'en-US': enUS,
    'zh-CN': zhCN
  }
})

setI18nLanguage('en-US')
export function setI18nLanguage (lang) {
  i18n.locale = lang
}

export function getI18nLanguage () {
  return i18n.locale
}
export function i18nRender (key) {
  return i18n.t(`${key}`)
}
export default i18n
